import React, { useState, useEffect } from "react";
import Api from "../api/api";

const Index = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const api = new Api();
      await api.getPlanetList().then((res) => {
        if (!res.data) {
          setError('No Planet')
        }
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
        setError(err)
      })
      .finally(() => {
        setLoading(false);
      })
    }
    getData()
   }, []);

  return (
    <div>
      <h1>API Planet</h1>
      {loading && <div>A moment please...</div>}
      {error && (
        <div>{`There is a problem fetching the post data - ${error}`}</div>
      )}
      <ul>
        {data &&
          data.map((planet) => (
            <li key={planet.key}>
              <h3>{planet.name}</h3>
              <h3>{planet.description}</h3>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default Index;