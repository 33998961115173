import React from "react";

const Welcome = () => {
  return (
    <div>
      Welcome to my website!
    </div>
  )
}

export default Welcome;